import React, { useState, useEffect } from 'react';
import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser from 'react-html-parser';

import { content_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
import Preview from '../Preview/Preview';

import AssecImage from "../ASSEC_Image/Image"

// import DivSharer from '../DivSharer/DivSharer';

import { language } from '../WEBContent/Language';

export default function Menu(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

	const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info]);

    return (isloading ?
		    <Loader />
		:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="manu-detail my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-end mb-3">
                            <a className="btn btn-link" href={'./manu/all'} >
                                <i className="bi bi-arrow-left"></i> {language[lang].back}
                            </a>
                        </div>
                    </div>
                    {
                        content.content.map((el, k) => {  
                            return (
                                <div key={k} className="row">
                                    <div className='col-12'>
                                        <div className={"col-image float-md-start me-md-4 mb-3"}>
                                            <a
                                                href={el['media']['image'][0]['link']}
                                                title={lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                data-fancybox="manu-gallery"
                                                data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) || el['media']['image'][0]['link'].includes('webp') || el['media']['image'][0]['link'].includes('jpg') || el['media']['image'][0]['link'].includes('png') ? '' : el['media']['image'][0]['name']}
                                            >
                                                <div className="card border-0 shadow menu-image">
                                                    {
                                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                            <AssecImage 
                                                                linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                width="400"
                                                                height="300"
                                                                fit='crop'
                                                                type='img'
                                                                sClass="img-fluid" 
                                                                alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                            />
                                                        :
                                                            el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                <AssecImage 
                                                                    linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                    width="400"
                                                                    height="300"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid" 
                                                                    alt={el['media']['image'][0]['name']}
                                                                />
                                                            :
                                                                null
                                                    }
                                                </div>
                                            </a>
                                        </div>
                                        <div className="manu-content">
                                            <h1 className={el.title !== '' ? "titulo-primario mb-3" : "d-none"}>
                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                            </h1>
                                            {
                                                el.text !== '' && el.text !== '<p></p>\n' ?

                                                    <div className="manu-text">
                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                    </div>
                                                : null 
                                            }
                                        </div>
                                    </div>

                                    {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                            <div className="col-12 manu-gallery mt-3">
                                                <div className="row">
                                                    {
                                                    el.media.image.map((el_img, k_img) => {
                                                        if(k_img > 0) {

                                                            return (
                                                                <div key={k_img} className="col-6 col-md-3 col-lg-2 mb-4">
                                                                    <a
                                                                        href={el_img.link}
                                                                        data-fancybox="manu-gallery"
                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                    >
                                                                        <figure className="figure m-0">
                                                                            <AssecImage 
                                                                                linkImage={el_img.link}
                                                                                width="400"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid shadow-sm" 
                                                                                alt={el_img.name}
                                                                            />
                                                                            {
                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                    ''
                                                                                :
                                                                                    <figcaption className='figure-caption p-3 text-center' >{el_img.name}</figcaption>
                                                                            }
                                                                        </figure>
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                            <div className="col-12 manu-documents mt-3">
                                                {
                                                    el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                        return (
                                                            <a key={k_doc} className="btn btn-primary mb-2 me-2" href={el_doc.link} rel="noreferrer" target="_blank">
                                                                {el_doc.name} <i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>
                                        :
                                            el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                <div className="col-12 manu-documents mt-3">
                                                    {
                                                    el.media.doc.map((el_doc, k_doc) => {
                                                        return (
                                                            <a key={k_doc} className="btn btn-primary mb-2 me-2" href={el_doc.link} target="_blank" rel="noreferrer">
                                                                {el_doc.name} <i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                            </a>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            :
                                                null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}