import React, { useState, useEffect, useRef } from 'react';

import ReactHtmlParser from 'react-html-parser';
//import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, translate, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
//import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function BtandCards(props) {

    const [, set_dados] = useState(null)
	const brands_list = useRef([])

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});
    
    const [brands_title, set_brands_title] = useState('')
    const [brands_subtitle, set_brands_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    //const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)

            for (let el of props.info.state.dados_config.content[0].components) {

                if(el.info.tag === 'brand' && el.info.active) {
                    set_brands_title(translate(el.info.title, lang))
                    set_brands_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            let getBrands = async () => {
                reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/brand_short`)
                  .then(res => {
            
                    if(res.data.length > 0) {
                        //console.log('-->', res.data[0].content[0]?.media?.image)
    
                        
                        set_dados(brands_list.current = res.data[0].content[0]?.media?.image)
                        setLoading(false)
                    }
            
                  }).catch(erro => console.log('Erro1', erro))
            }
            getBrands()

            /*let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/brand/order`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }*/
            //getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info, lang]);

    return (isloading ?
		    <Loader />
		:
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="brand-cards my-5">
                <div id="brand-cards" className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {brands_title}
                            </h3>
                            {
                                brands_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                    {brands_subtitle}
                                    </h4>
                                :
                                null
                            }
                            <hr></hr>
                        </div>
                    </div>
                    {
                        content !== undefined && content.id !== '' && 0 ?
                            <div className="row">
                                <div className="div-content col-12 mb-3 mb-md-5">
                                    <h2 className="titulo-primario">
                                        {/*content['content'][0] !== undefined && content['content'][0]['title'] !== undefined && content.content[0].title !== '' ? content.content[0].title : 'Parceiros'*/}
                                        {(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].title : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].title === undefined || content['content'][0].language[lang_id].title === '' ? content['content'][0].title : content['content'][0].language[lang_id].title))}
                                    </h2>
                                    <h2 className={content['content'][0] !== undefined && content['content'][0]['subtitle'] !== undefined && content.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>
                                        {/*content['content'][0] !== undefined && content['content'][0]['subtitle'] !== undefined && content.content[0].subtitle !== '' ? content.content[0].subtitle : ''*/}
                                        {(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].subtitle : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].subtitle === undefined || content['content'][0].language[lang_id].subtitle === '' ? content['content'][0].subtitle : content['content'][0].language[lang_id].subtitle))}
                                    </h2>
                                    <div className={"div-text"}>
                                        {/*content['content'][0] !== undefined && content['content'][0]['text'] !== undefined && content.content[0].text !== '' ? ReactHtmlParser(content.content[0].text) : ''*/}
                                        {ReactHtmlParser(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].text : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].text === undefined || content['content'][0].language[lang_id].text === '' ? content['content'][0].text : content['content'][0].language[lang_id].text))}
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }
                    {
                        brands_list.current !== undefined && brands_list.current !== null && brands_list.current.length > 0 ?                      
                            <div className="row justify-content-center">
                                {
                                    
                                    brands_list.current.map((v, k) => {  
                                        return (
                                            <div key={k} className="col-6 col-md-4 col-lg-2 mb-4">
                                                {
                                                    v.url !== undefined && v.url !== '' ?
                                                        <a href={v.url.includes('http') ? v.url : 'https://' + v.url } target="_blank" rel="noreferrer" title={translate(v.name, lang)}>
                                                            <div className="card">
                                                                <div className="brand-image">
                                                                    <AssecImage
                                                                        linkImage={v.link !== '' ? v.link : repo_img_link('nophoto.webp')}
                                                                        width="400"
                                                                        height="300"
                                                                        fit='fit'
                                                                        type='img'
                                                                        sClass='img-fluid'
                                                                        alt={translate(v.name, lang)}
                                                                        title={translate(v.name, lang)}
                                                                    />
                                                                </div>
                                                                {
                                                                    (translate(v.name, lang) === '' || translate(v.name, lang).includes('webp') || translate(v.name, lang).includes('jpg') || translate(v.name, lang).includes('png')) ?
                                                                        null 
                                                                    :
                                                                        <div className="card-body text-center">
                                                                            <div className="brand-title">
                                                                                {translate(v.name, lang)}
                                                                            </div>
                                                                            {/*<div className="service-text">
                                                                                {translate(v.text, lang)}
                                                                            </div>*/}                                                            
                                                                        </div>
                                                                }
                                                            </div>
                                                        </a>
                                                    :
                                                        <div className="card">
                                                            <div className="brand-image">
                                                                <AssecImage
                                                                    linkImage={v.link !== '' ? v.link : repo_img_link('nophoto.webp')}
                                                                    width="400"
                                                                    height="300"
                                                                    fit='fit'
                                                                    type='img'
                                                                    sClass='img-fluid'
                                                                    alt={translate(v.name, lang)}
                                                                    title={translate(v.name, lang)}
                                                                />
                                                            </div>
                                                            {
                                                                (translate(v.name, lang) === '' || translate(v.name, lang).includes('webp') || translate(v.name, lang).includes('jpg') || translate(v.name, lang).includes('png')) ?
                                                                    null 
                                                                :
                                                                    <div className="card-body text-center">
                                                                        <div className="brand-title">
                                                                            {translate(v.name, lang)}
                                                                        </div>
                                                                        {/*<div className="brand-text">
                                                                            {translate(v.text, lang)}
                                                                        </div>*/}                                                            
                                                                    </div>
                                                            }
                                                        </div>
                                                }
                                            </div> 
                                        )
                                    })
                                        
                                }
                            </div>
                        :
                         null
                    } 
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}