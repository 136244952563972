import React, { useState, useEffect } from 'react';

import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser  from 'react-html-parser';

import { content_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
import Preview from '../Preview/Preview';

import AssecImage from "../ASSEC_Image/Image"

import { language } from '../WEBContent/Language';

export default function Partner(props) {
    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [link, set_link] = useState('')

	const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)

            console.log(props.info.state.dados_content)

            if(props.info.state.dados_content.content[0].contacts !== undefined && props.info.state.dados_content.content[0].contacts['website'] !== undefined && props.info.state.dados_content.content[0].contacts['website'] !== '') {
                const { hostname } = new URL(props.info.state.dados_content.content[0].contacts['website'].includes('http') ? props.info.state.dados_content.content[0].contacts['website'] : 'http://' + props.info.state.dados_content.content[0].contacts['website'])
                set_link(hostname)
            }
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info]);

    return (isloading ?
		<Loader />
	:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="partner-detail my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-end mb-3">
                            <a className="btn btn-link" href={'./partner/all'} >
                                <i className="bi bi-arrow-left"></i> {language[lang].back}
                            </a>
                        </div>
                    </div>
                    {
                        content.content.map((el, k) => {  
                            return (
                                <div key={k} className="row">
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className="div-content col-12 col-md-9 col-lg-9 order-last order-md-first pe-md-4 pe-lg-5">
                                                <h1 className={el.title !== '' ? "titulo-primario mb-3" : "d-none"}>
                                                    {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                </h1>

                                                {
                                                    (el.contacts['morada'] !== undefined && el.contacts.morada !== '') ||
                                                    (el.contacts['website'] !== undefined && el.contacts.website !== '') ||
                                                    el.contacts.email !== '' ||
                                                    content.info.category !== '' ||
                                                    el.contacts.tlf !== '' ||
                                                    el.contacts.tlm !== '' ?
                                                        <div className={"div-text mb-3"}>
                                                            <div style={{display: 'inline-flex', flexWrap: 'nowrap', flexDirection: 'column'}}>
                                                                {
                                                                    el.contacts['morada'] !== undefined && el.contacts.morada !== '' ?
                                                                        <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} className="mb-2">
                                                                            <i className="bi bi-house me-2"></i>
                                                                            {ReactHtmlParser(el.contacts.morada.replaceAll('\n', '<br/>'))}
                                                                        </span>
                                                                    :
                                                                        null
                                                                } 
                                                                {
                                                                    el.contacts['website'] !== undefined && el.contacts.website !== '' ?
                                                                        <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}>
                                                                            <i className="bi bi-globe me-2"></i> 
                                                                            <a href={el.contacts.website.includes('http') ? el.contacts.website : 'http://' + el.contacts.website} target="_blank" rel="noreferrer">
                                                                                {
                                                                                    link !== undefined ? link : null
                                                                                    //el.contacts.website
                                                                                    //el.contacts.website.includes(el.contacts.website_type) ? el.contacts.website : el.contacts.website_type + el.contacts.website
                                                                                }
                                                                            </a>
                                                                        </span>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    el.contacts.email !== '' ?
                                                                        <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}>
                                                                            <i className="bi bi-envelope me-2"></i>
                                                                            <a href={'mailto: '+ el.contacts.email} target="_blank" rel="noreferrer">
                                                                                {el.contacts.email}
                                                                            </a>
                                                                        </span>
                                                                    :
                                                                        null
                                                                } 
                                                                {
                                                                    content.info.category !== '' ?
                                                                        <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}} className='d-none'>
                                                                            <strong>{content.info.category}</strong>
                                                                        </span>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    el.contacts.tlf !== '' ?
                                                                        <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}>
                                                                            <i className="bi bi-telephone me-2"></i>
                                                                            <a href={"tel:" + el.contacts.tlf}>{el.contacts.tlf}</a>
                                                                        </span>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    el.contacts.tlm !== '' ?
                                                                        <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}>
                                                                            <i className="bi bi-phone me-2"></i>
                                                                            <a href={"tel: " + el.contacts.tlm}>{el.contacts.tlm}</a> <br /></span>
                                                                    :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                     null 
                                                }
                                                {
                                                    (el.abstract !== '' || (el.text !== '' && el.text !== '<p></p>\n')) ?
                                                        <div className='partner-description mb-3'>
                                                            {
                                                                el.abstract !== '' ?
                                                                    <div className={"partner-abstract mb-3"}>
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                    </div>
                                                                : null 
                                                            }
                                                            {
                                                                el.text !== '' && el.text !== '<p></p>\n' ?

                                                                    <div className="partner-text">
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                                    </div>
                                                                : null 
                                                            }
                                                        </div>
                                                    :
                                                        null
                                                }
                                            </div>
                                            {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                    <div className={"div-image col-12 col-md-3 col-lg-3 order-first order-md-last mt-3 mb-5"}>
                                                        <div className="card border-0 shadow">
                                                            {
                                                                el['language'][lang_id]['media'][0]['url'] !== undefined && el['language'][lang_id]['media'][0]['url'] !== '' ?
                                                                    <a href={el['language'][lang_id]['media'][0]['url'].includes('http') ? el['language'][lang_id]['media'][0]['url'] : 'https://' + el['language'][lang_id]['media'][0]['url'] } target="_blank" rel="noreferrer" title={el['language'][lang_id]['media'][0]['link'].includes(el['language'][lang_id]['media'][0]['name']) || el['language'][lang_id]['media'][0]['name'].includes('webp') || el['language'][lang_id]['media'][0]['name'].includes('jpg') || el['language'][lang_id]['media'][0]['name'].includes('png') ? '' : el['language'][lang_id]['media'][0]['name']}>
                                                                        <AssecImage 
                                                                            linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                            width="400"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                        />
                                                                    </a>

                                                                :
                                                                    <a
                                                                        href={el['language'][lang_id]['media']['image'][0]['link']}
                                                                        data-fancybox="partner-gallery"
                                                                        data-caption={el['language'][lang_id]['media']['image'][0]['link'].includes(el['language'][lang_id]['media']['image'][0]['name']) || el['language'][lang_id]['media']['image'][0]['name'].includes('webp') || el['language'][lang_id]['media']['image'][0]['name'].includes('jpg') || el['language'][lang_id]['media']['image'][0]['name'].includes('png') ? '' : el['language'][lang_id]['media']['image'][0]['name']}
                                                                    >
                                                                        <AssecImage 
                                                                            linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                            width="400"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                        />
                                                                    </a>
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                        <div className={"div-image col-12 col-md-3 col-lg-3 order-first order-md-last mt-3 mb-5"}>
                                                            <div className="card border-0 shadow">
                                                                {
                                                                    el['media']['image'][0]['url'] !== undefined && el['media']['image'][0]['url'] !== '' ?

                                                                        <a href={el['media']['image'][0]['url'].includes('http') ? el['media']['image'][0]['url'] : 'https://' + el['media']['image'][0]['url'] } target="_blank" rel="noreferrer" title={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) || el['media']['image'][0]['name'].includes('webp') || el['media']['image'][0]['name'].includes('jpg') || el['media']['image'][0]['name'].includes('png') ? '' : el['media']['image'][0]['name']}>
                                                                            <AssecImage 
                                                                                linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                width="400"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el['media']['image'][0]['name']}
                                                                            />
                                                                        </a>
                                                                    :
                                                                        <a
                                                                            href={el['media']['image'][0]['link']}
                                                                            data-fancybox="partner-gallery"
                                                                            data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) || el['media']['image'][0]['name'].includes('webp') || el['media']['image'][0]['name'].includes('jpg') || el['media']['image'][0]['name'].includes('png') ? '' : el['media']['image'][0]['name']}
                                                                        >
                                                                            <AssecImage 
                                                                                linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                width="400"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el['media']['image'][0]['name']}
                                                                            />
                                                                        </a>
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                        null
                                            }
                                        </div>
                                    </div>

                                    {/*<div className='col-12 partner-description'>
                                        {
                                            el.abstract !== '' ?
                                                <div className={"partner-abstract mb-3"}>
                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                </div>
                                            : null 
                                        }
                                        {
                                            el.text !== '' && el.text !== '<p></p>\n' ?

                                                <div className="partner-text">
                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                </div>
                                            : null 
                                        }
                                    </div>*/}
                                    {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                            <div className="col-12 partner-gallery mt-3">
                                                <div className="row">
                                                    {
                                                    el.media.image.map((el_img, k_img) => {
                                                        if(k_img > 0) {

                                                            return (
                                                                <div key={k_img} className="col-6 col-md-3 col-lg-2 mb-4">
                                                                    {
                                                                        el_img.url !== undefined && el_img.url !== '' ?
                                                                            <a 
                                                                                href={el_img.url.includes('http') ? el_img.url : 'https://' + el_img.url }
                                                                                target="_blank" 
                                                                                rel="noreferrer" 
                                                                                title={el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') ? '' : el_img.name}>
                                                                                <figure className="figure m-0">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="400"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid shadow-sm" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='figure-caption p-3 text-center' >{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                        :

                                                                            <a
                                                                                href={el_img.link}
                                                                                data-fancybox="partner-gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="figure m-0">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="400"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid shadow-sm" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='figure-caption p-3 text-center' >{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                    }
                                                                    
                                                                </div>
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            null
                                    }
                                    {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                <div className="col-12 partner-documents mt-3">
                                                    {
                                                        el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                            return (
                                                                <a key={k_doc} className="btn btn-primary mb-2 me-2" href={el_doc.link} rel="noreferrer" target="_blank">
                                                                    {el_doc.name} <i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                </a>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                    <div className="col-12 partner-documents mt-3">
                                                        {
                                                        el.media.doc.map((el_doc, k_doc) => {
                                                            return (
                                                                <a key={k_doc} className="btn btn-primary mb-2 me-2" href={el_doc.link} target="_blank" rel="noreferrer">
                                                                    {el_doc.name} <i className="bi bi-filetype-pdf ps-2 fs-6"></i>
                                                                </a>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                :
                                                    null
                                        }
                                </div>
                            )
                        })
                    }
                </div> 
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}