import React, { useState, useEffect, useRef } from 'react'
import { useParams, Redirect } from "react-router-dom";

import "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import NormalContent from './Normal';
import CardContent from './Card';
import AccordionContent from './Accordion';
import TabContent from './Tabs';

import ListContent from './List';
import ListLeftContent from './ListLeft';
import ListRightContent from './ListRight';
import ListAltContent from './ListAlt';
import ListBannerContent from './ListBanner';

import NotFound from './NotFound';
import Maintenance from './Maintenance';
import ContactContent from './ContactForm';
import ContactLandingContent from './ContactFormLanding';

import NewsCardsContent from './NewsCards';
import NewsContent from './News';

import PressCardsContent from './PressCards';

import GalleryCardsContent from './GalleryCards';
import GalleryContent from './Gallery';

import PartnerCardsContent from './PartnerCards';
import PartnerContent from './Partner';

import ServiceCardsContent from './ServiceCards';
import ServiceContent from './Service';

import ProductCardsContent from './ProductCards';
import ProductContent from './Product';

import BrandCardsContent from './BrandCards';
import BrandContent from './Brand';

import MenuCardsContent from './MenuCards';
import MenuContent from './Menu';


import AccommodationCardsContent from './AccommodationCards';
import AccommodationContent from './Accommodation';

import RestaurantCardsContent from './RestaurantCards';
import RestaurantContent from './Restaurant';

import EventsCardsContent from './EventsCards';
import EventsContent from './Events';

import AnimationCardsContent from './AnimationCards';
import AnimationContent from './Animation';

// import RoutesCardsContent from './RoutesCards';
// import RoutesContent from './Routes';
import RoutesCardsContent from '../Estrela/RoutesCards';
import RoutesContent from '../Estrela/Routes';

//import Livecam from '../Navbar_Extra/Livecam';
//import Accesses from '../Navbar_Extra/Accesses';
import Loader from '../../../loader';

import * as Hosts from '../../../Hosts';
import { reqGET, set_language, content_language } from '../../../Utils';
import { getTokenInfo } from '../../../UseToken';

import './Content.css';

export default function Content(props) {

    const { pag_id, slug, article_id, gallery_id, partner_id, service_id, product_id, brand_id, menu_id, press_id, routes_id, accommodation_id, restaurant_id, event_id, animation_id, preview_id } = useParams();
    const [isloading, setLoading] = useState(true);

    const [, set_dados] = useState(null);
	const content = useRef(null);
	const dados_config = useRef(null);
    
    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }
    const navbar = useRef(data_interface);

    const [lang, set_lang] = useState('pt')
    const [, set_lang_id] = useState('1')
    //const [/*refresh*/, setRefresh] = useState(0);


    useEffect(() => {

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
          set_lang('pt')
          set_language('pt')
          set_lang_id('1')
        }
        else {
          set_lang(localStorage.getItem('lang'))
          content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
              set_lang_id(el.id)
            }
          })
        }

        if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
            set_dados(navbar.current = props.dados_navbar)
        }
      
        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
        }

        (async () => {

            /*const getConfig = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/config`)
                    .then(async res => {
                
                        if(res.data.length > 0) {
                            set_dados(dados_config.current = res.data[0])
                        }
                
                    })
                    .catch(erro => console.log('Erro', erro))
        
                await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                    .then(async res => {
                        for await (let v of res.data) {
                        // res.data.forEach((v) => {
                            if (v.info.principal === true) {
            
                                if (lang !== 'pt') {
                                    for await (let cl of content_language) {
                                    // content_language.forEach((cl) => {
                                        let x = 0
        
                                        if (cl.code === lang) {
            
                                            v.content.childs.forEach((el) => {
                                                if (el.id_content !== '') {
                                                    if (el.children !== undefined) {
                                                        x = x - el.children.length
                                                        //for await (let el_c of el.children) {
                                                        el.children.forEach(async (el_c) => {
                                                            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                                .then(res_3 => {
                                                                    if (res_3.data.length > 0) {
                                                                        if (res_3.data[0].info.language.length > 0) {
                                                                            if (res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                                if (res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                                    el_c.title = res_3.data[0].info.language[cl.id]['name'];
            
                                                                                    x++
                                                                                    if (v.content.childs.length === x) {
                                                                                        setRefresh(refresh => refresh + 1)
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            x++
                                                                            if (v.content.childs.length === x) {
                                                                                setRefresh(refresh => refresh + 1)
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        x++
                                                                        if (v.content.childs.length === x) {
                                                                            setRefresh(refresh => refresh + 1)
                                                                        }
                                                                    }
                                                                })
                                                        })
                                                    }
            
                                                    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                        .then(res_2 => {
                                                            if (res_2.data.length > 0) {
                                                                if (res_2.data[0].info.language.length > 0) {
                                                                    if (res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                        if (res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                            el.title = res_2.data[0].info.language[cl.id]['name'];
            
                                                                            x++
                                                                            if (v.content.childs.length === x) {
                                                                                setRefresh(refresh => refresh + 1)
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    x++
                                                                    if (v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                x++
                                                                if (v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                            }
                                                        })
            
            
                                                }
                                                else {
                                                    if (el['language'] !== undefined && el['language'] !== null) {
                                                        if (el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') {
                                                            el.title = el.language[cl.id]['title']
            
                                                            x++
                                                            if (v.content.childs.length === x) {
                                                                setRefresh(refresh => refresh + 1)
                                                            }
                                                        }
                                                    }
                                                }
            
                                            })
            
                                        }
                                    }
            
                                }
            
                                set_dados(navbar.current = v);
                            }
                        }
            
                    })
                    .catch(erro => console.log('ERROR', erro))
            }*/

            //await getConfig()

            let getPreviewInfoId = async (id) => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                    .then(res => { 
                        if(res.data.length > 0) {

                            if(getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile !== undefined && getTokenInfo().profile !== null && getTokenInfo().profile !== '') {
                                res.data[0].info['preview'] = true
                                set_dados(content.current = res.data[0])
                                setLoading(false)
                            }
                            else {
                            }
                        }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }

            let getInfoId = async (id) => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                    .then(res => { 
                        if(res.data.length > 0) {

                            var hoje = new Date();
                            let status = 0
                            if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                var date_p = new Date(res.data[0].info.date.published);

                                status = 1
                                if(hoje >= date_p) 
                                    status = 2
                            }

                            if(status !== 2) {
                                //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                                res.data[0].info.layout = '404'
                            }

                            set_dados(content.current = res.data[0])
                            setLoading(false)
                        }
                        else {
                            if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')
                                set_dados(content.current = {id: id, info: {type: 'news', layout: '404'}, content: []})
                            else
                                set_dados(content.current = {id: id, info: {type: 'content', layout: '404'}, content: []})

                            setLoading(false)
                        }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }

            let getInfoRoutesId = async (id) => {
                await reqGET(`${Hosts.SIMCore_API}/platform/routes/${id}`)
                    .then(res => { 
                        if(res.data.length > 0) {

                            if(res.data[0].activo && res.data[0].visible !== '') {
                                res.data[0].info = {}
                                res.data[0].info.type = 'routes'
                                res.data[0].info.layout = 'normal'
                            }
                            else {
                                res.data[0].info.layout = '404'
                            }

                            set_dados(content.current = res.data[0])
                            setLoading(false)
                        }
                        else {
                            if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')
                                set_dados(content.current = {id: id, info: {type: 'news', layout: '404'}, content: []})
                            else
                                set_dados(content.current = {id: id, info: {type: 'content', layout: '404'}, content: []})

                            setLoading(false)
                        }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
        
            let getInfoSlug = async (slug) => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                    .then(res => { 
                        if(res.data.length > 0) {

                            var hoje = new Date();
                            let status = 0
                            if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                var date_p = new Date(res.data[0].info.date.published);

                                status = 1
                                if(hoje >= date_p) 
                                    status = 2
                            }

                            if(status !== 2) {
                                //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                                res.data[0].info.layout = '404'
                            }
                            set_dados(content.current = res.data[0])

                            setLoading(false)
                        }
                        else {
                            //console.log('slug: ', slug)
                            set_dados(content.current = {id: '', info: {type: slug}})
    
                            setLoading(false)
                        }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }

            if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                getPreviewInfoId(preview_id)
                .then(() => {
                })
            }
            else if(pag_id !== undefined && pag_id !== 'undefined' && pag_id !== '') {
                getInfoId(pag_id)
            }
            else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {

                if(slug === 'contacts') {
                    //console.log('0000000')
                    set_dados(content.current = {id: '', info: {type: 'contact', layout: 'all'}, content: []})
                    setLoading(false)
                }
                else {
                    getInfoSlug(slug)
                }

            }
            else if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')  {
                if(article_id === 'all') {
                    // content.current.info['type'] = 'news'
                    // content.current.info['layout'] = 'all'
                    
                    set_dados(content.current = {id: '', info: {type: 'news', layout: 'all'}, content: []})

                    // set_dados(content)
                    setLoading(false)
                }
                else {
                    getInfoId(article_id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
            }
            else if(press_id !== undefined && press_id !== 'undefined' && press_id !== '')  {
                //if(press_id === 'all') {
                    // content.current.info['type'] = 'press'
                    // content.current.info['layout'] = 'all'
                    
                    // set_dados(content)
                    set_dados(content.current = {id: '', info: {type: 'press', layout: 'all'}, content: []})
                    setLoading(false)
                //}
                /*else {
                    getInfoId(press_id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }*/
            }
            else if(gallery_id !== undefined && gallery_id !== 'undefined' && gallery_id !== '')  {
                if(gallery_id === 'all') {

                    set_dados(content.current = {id: '', info: {type: 'gallery', layout: 'all'}, content: []})
                    setLoading(false)

                }
                else {
                    getInfoId(gallery_id)
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            else if(partner_id !== undefined && partner_id !== 'undefined' && partner_id !== '')  {
                if(partner_id === 'all') {

                    set_dados(content.current = {id: '', info: {type: 'partner', layout: 'all'}, content: []})
                    setLoading(false)

                }
                else {
                    getInfoId(partner_id)
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            else if(service_id !== undefined && service_id !== 'undefined' && service_id !== '')  {
                if(service_id === 'all') {

                    set_dados(content.current = {id: '', info: {type: 'service', layout: 'all'}, content: []})
                    setLoading(false)

                }
                else {
                    getInfoId(service_id)
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            else if(product_id !== undefined && product_id !== 'undefined' && product_id !== '')  {
                if(product_id === 'all') {

                    set_dados(content.current = {id: '', info: {type: 'product', layout: 'all'}, content: []})
                    setLoading(false)

                }
                else {
                    getInfoId(product_id)
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            else if(brand_id !== undefined && brand_id !== 'undefined' && brand_id !== '')  {
                if(brand_id === 'all') {

                    set_dados(content.current = {id: '', info: {type: 'brand', layout: 'all'}, content: []})
                    setLoading(false)

                }
                else {
                    getInfoId(brand_id)
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            else if(menu_id !== undefined && menu_id !== 'undefined' && menu_id !== '')  {
                if(menu_id === 'all') {

                    set_dados(content.current = {id: '', info: {type: 'menu', layout: 'all'}, content: []})
                    setLoading(false)

                }
                else {
                    getInfoId(menu_id)
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            else if(event_id !== undefined && event_id !== 'undefined' && event_id !== '')  {
                if(event_id === 'all') {
                    //content.current.info['type'] = 'event_id'
                    // content.current.info['layout'] = 'all'

                    set_dados(content.current = {id: '', info: {type: 'event', layout: 'all'}, content: []})
                    setLoading(false)
                }
                else {
                    getInfoId(event_id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
            }
            else if(animation_id !== undefined && animation_id !== 'undefined' && animation_id !== '')  {
                if(animation_id === 'all') {
                    //content.current.info['type'] = 'animation'
                    // content.current.info['layout'] = 'all'

                    set_dados(content.current = {id: '', info: {type: 'animation', layout: 'all'}, content: []})
                    setLoading(false)
                }
                else {
                    getInfoId(animation_id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
            }
            else if(routes_id !== undefined && routes_id !== 'undefined' && routes_id !== '')  {
                if(routes_id === 'all') {
                    //content.current.info['type'] = 'routes'
                    // content.current.info['layout'] = 'all'

                    set_dados(content.current = {id: '', info: {type: 'routes', layout: 'all'}, content: []})
                    setLoading(false)
                }
                else {
                    getInfoRoutesId(routes_id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
            }
            else if(accommodation_id !== undefined && accommodation_id !== 'undefined' && accommodation_id !== '')  {
                if(accommodation_id === 'all') {
                    //content.current.info['type'] = 'accommodation'
                    // content.current.info['layout'] = 'all'

                    set_dados(content.current = {id: '', info: {type: 'accommodation', layout: 'all'}, content: []})
                    setLoading(false)
                }
                else {
                    getInfoId(accommodation_id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
            }
            else if(restaurant_id !== undefined && restaurant_id !== 'undefined' && restaurant_id !== '')  {
                if(restaurant_id === 'all') {
                    //content.current.info['type'] = 'restaurant'
                    // content.current.info['layout'] = 'all'

                    set_dados(content.current = {id: '', info: {type: 'restaurant', layout: 'all'}, content: []})
                    setLoading(false)
                }
                else {
                    getInfoId(restaurant_id)
                        .then((res) => {
                            if(res === true){
                                setLoading(false)
                            }
                        })
                }
            }
            else {
                
                if(dados_config.current.info.maintenance) {
                    set_dados(content.current = {id: '', info: {type: 'content', layout: 'maintenance'}, content: []})
                    setLoading(false)
                }
                else {
                    set_dados(content.current = {id: '', info: {type: 'content', layout: '404'}, content: []})
                    setLoading(false)
                }


                console.log('aaa')
            }
            
        })()
        
    }, [props, lang, pag_id, slug, article_id, partner_id, service_id, product_id, brand_id, menu_id, routes_id, accommodation_id, restaurant_id, gallery_id, press_id, event_id, animation_id, preview_id]);

    return (isloading ?
		<Loader />
    :
        (() => {
            switch (content.current.info.type) {
                case 'content':
                    switch (content.current.info.layout) {

                        case 'card':
                            return (
                                <CardContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case 'accordion':
                            return (
                                <AccordionContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case 'tabs':
                            return (
                                <TabContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'contact':
                            return (
                                <ContactContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'list':
                            return (
                                <ListContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 
                        
                        case 'list-left':
                            return (
                                <ListLeftContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        case 'list-right':
                            return (
                                <ListRightContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        case 'list-alt':
                            return (
                                <ListAltContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        case 'list-banner':
                            return (
                                <ListBannerContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 
                    
                        case '404':
                            return (
                                <NotFound info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 
                        
                        case 'maintenance':
                            return (
                                <Maintenance info={{ state: { id: '', preview: '', slug: '', dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }
                
                case 'news':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <NewsCardsContent info={{ state: { id: article_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <NewsContent info={{ state: { id: article_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        case '404':
                            return (
                                <NotFound info={{ state: { id: article_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: article_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'press':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <PressCardsContent info={{ state: { id: press_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        /*case 'normal':
                            return (
                                <PressContent info={{ state: { id: press_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )*/
                        case '404':
                            return (
                                <NotFound info={{ state: { id: press_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            //console.log('aki aki');
                            return (
                                <PressCardsContent info={{ state: { id: press_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'gallery':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <GalleryCardsContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <GalleryContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: gallery_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'partner':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <PartnerCardsContent info={{ state: { id: partner_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <PartnerContent info={{ state: { id: partner_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: partner_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: partner_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'service':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <ServiceCardsContent info={{ state: { id: service_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <ServiceContent info={{ state: { id: service_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: service_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: service_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'product':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <ProductCardsContent info={{ state: { id: product_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <ProductContent info={{ state: { id: product_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: product_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: product_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'brand':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <BrandCardsContent info={{ state: { id: brand_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <BrandContent info={{ state: { id: brand_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: brand_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: brand_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                }

                case 'menu':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <MenuCardsContent info={{ state: { id: menu_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <MenuContent info={{ state: { id: menu_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: menu_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: menu_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'event':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <EventsCardsContent info={{ state: { id: event_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <EventsContent info={{ state: { id: event_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: event_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: event_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'animation':
                case 'activity':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <AnimationCardsContent info={{ state: { id: animation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <AnimationContent info={{ state: { id: animation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: animation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: animation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'routes':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <RoutesCardsContent info={{ state: { id: routes_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <RoutesContent info={{ state: { id: routes_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: routes_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: routes_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'accommodation':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <AccommodationCardsContent info={{ state: { id: accommodation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <AccommodationContent info={{ state: { id: accommodation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: accommodation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: accommodation_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'restaurant':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <RestaurantCardsContent info={{ state: { id: restaurant_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )

                        case 'normal':
                            return (
                                <RestaurantContent info={{ state: { id: restaurant_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: restaurant_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: restaurant_id, preview: preview_id, slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                case 'contact':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <ContactLandingContent info={{ state: { id: '', preview:'', slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: '', preview: '', slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: '', preview: '', slug: slug, dados_content: content.current, dados_config: dados_config.current, dados_navbar: navbar.current } }} />
                            )
                    }

                default:
                    return (
                        <Redirect to="/" />
                    )
            }
   
        })()
    )
}
