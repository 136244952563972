import React, { useState, useEffect } from 'react';

import ReactHtmlParser from 'react-html-parser';
//import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, translate, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Footer from '../Footer/Footer_00';
//import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

import "@fancyapps/ui/dist/fancybox.css";

export default function MenuCards(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [menu_title, set_menu_title] = useState('')
    const [menu_subtitle, set_menu_subtitle] = useState('')

    const [categories, set_categories] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)

            for (let el of props.info.state.dados_config.content[0].components) {

                if(el.info.tag === 'tag_menu' && el.info.active) {
                    set_menu_title(translate(el.info.title, lang))
                    set_menu_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })


            let getInfoList = async () => {

                let cat = await reqGET(`${Hosts.SIMCore_API}/web/config/attributes/type/${Hosts.companycode}/catmenu`)
                //console.log('cat', cat)

                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/menu/order`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            let aux_cat = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }

                              if(v.content[0].category !== '') {
                                let x_cat = cat.data.filter(e => e.id === v.content[0].category)
                                if(x_cat !== undefined && x_cat !== null && x_cat.length === 1) {
                                    let x_cat_2 = aux_cat.filter(e => e.id === x_cat[0].id)
                                    if(x_cat_2 !== undefined && x_cat_2 !== null && x_cat_2.length === 0) {
                                        aux_cat.push(x_cat[0])
                                    }
                                }
                              }
                            })

                            set_categories(aux_cat)
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info, lang]);

    return (isloading ?
		    <Loader />
		:
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />
            <main className="menu-cards my-5">
                <div id="menu-cards" className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {menu_title}
                            </h3>
                            {
                                menu_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                    {menu_subtitle}
                                    </h4>
                                :
                                null
                            }
                            <hr></hr>
                        </div>
                    </div>
                    {
                        content !== undefined && content.id !== '' && 0 ?
                            <div className="row">
                                <div className="div-content col-12 mb-3 mb-md-5">
                                    <h2 className="titulo-primario">
                                        {(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].title : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].title === undefined || content['content'][0].language[lang_id].title === '' ? content['content'][0].title : content['content'][0].language[lang_id].title))}
                                    </h2>
                                    <h2 className={content['content'][0] !== undefined && content['content'][0]['subtitle'] !== undefined && content.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>
                                        {(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].subtitle : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].subtitle === undefined || content['content'][0].language[lang_id].subtitle === '' ? content['content'][0].subtitle : content['content'][0].language[lang_id].subtitle))}
                                    </h2>
                                    <div className={"div-text"}>
                                        {ReactHtmlParser(lang === 'pt' || content['content'][0].language === undefined ? content['content'][0].text : (content['content'][0].language[lang_id] === undefined || content['content'][0].language[lang_id].text === undefined || content['content'][0].language[lang_id].text === '' ? content['content'][0].text : content['content'][0].language[lang_id].text))}
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }

                    {
                        categories.length > 0 ?
                            <div className="row">
                                <div className='col-12'>
                                    <ul className="nav nav-tabs" role="tablist">
                                        {
                                            categories.map((el, k) => {
                                                return (
                                                    <li key={k} className="nav-item" role="presentation">
                                                        <button type="button" role="tab" className={"nav-link" + (k === 0 ? " active" : "")} id={"pills_page_" + k +"_tab"} data-bs-toggle="pill" data-bs-target={"#pills_page_" + k} aria-controls={"pills_page_" + k}>
                                                            {
                                                               /*el.info.icon !== undefined && el.info.icon !== '' ?
                                                                    <div className="menu-category-icon">
                                                                        <i className={"bi bi-" + ( el.info.icon)}></i>
                                                                    </div>
                                                                :
                                                                null*/
                                                            }
                                                            {translate(el.info.title, lang)}
                                                        </button>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className="tab-content">
                                        {
                                            categories.map((el, k) => {  
                                                return (
                                                    <div key={k} className={"tab-pane fade" + (k === 0 ? " show active" : '')} id={"pills_page_" + k} aria-labelledby={"pills_page_" + k +"_tab"} role="tabpanel" tabIndex="0">
                                                        {
                                                            list_content.length > 0 ?
                                                                <div className="row g-4">
                                                                    {
                                                                        list_content.map((content, k2) => {
                                                                            if (content.content[0].category === el.id){ 
                                                                                return (
                                                                                    <div key={k2} className="col-lg-6">
                                                                                        <div className='menu-flex-align-items'>
                                                                                            {
                                                                                                lang !== 'pt' && content.content[0]['language'] !== undefined && content.content[0]['language'][lang_id] !== undefined && content.content[0]['language'][lang_id] !== null && content.content[0]['language'][lang_id]['media'] !== null && content.content[0]['language'][lang_id]['media']['image'] !== undefined && content.content[0].language[lang_id]['media']['image'].length > 0 ?
                                                                                                    <div className="item-image">
                                                                                                        <a
                                                                                                            href={content.content[0]['language'][lang_id]['media']['image'][0]['link']}
                                                                                                            title={lang === 'pt' || content.content[0].language === undefined ? content.content[0].title : (content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id] === null || content.content[0].language[lang_id].title === undefined || content.content[0].language[lang_id].title === '' ? content.content[0].title : content.content[0].language[lang_id].title)}
                                                                                                            data-fancybox="manu-gallery"
                                                                                                            data-caption={lang === 'pt' || content.content[0].language === undefined ? content.content[0].title : (content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id] === null || content.content[0].language[lang_id].title === undefined || content.content[0].language[lang_id].title === '' ? content.content[0].title : content.content[0].language[lang_id].title)}
                                                                                                        >
                                                                                                            <AssecImage 
                                                                                                                linkImage={content.content[0]['language'][lang_id]['media']['image'][0]['link']}
                                                                                                                width="100"
                                                                                                                height="100"
                                                                                                                fit='crop'
                                                                                                                type='img'
                                                                                                                //sClass="img-fluid" 
                                                                                                                alt={content.content[0]['language'][lang_id]['media']['image'][0]['name']}
                                                                                                            />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                :
                                                                                                    content.content[0]['media'] !== undefined && content.content[0]['media']['image'] !== undefined && content.content[0]['media']['image'].length > 0 ?
                                                                                                        <div className="item-image">
                                                                                                            <a
                                                                                                                href={content.content[0]['media']['image'][0]['link']}
                                                                                                                title={lang === 'pt' || content.content[0].language === undefined ? content.content[0].title : (content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id] === null || content.content[0].language[lang_id].title === undefined || content.content[0].language[lang_id].title === '' ? content.content[0].title : content.content[0].language[lang_id].title)}
                                                                                                                data-fancybox="manu-gallery"
                                                                                                                data-caption={lang === 'pt' || content.content[0].language === undefined ? content.content[0].title : (content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id] === null || content.content[0].language[lang_id].title === undefined || content.content[0].language[lang_id].title === '' ? content.content[0].title : content.content[0].language[lang_id].title)}
                                                                                                            >
                                                                                                                <AssecImage 
                                                                                                                    linkImage={content.content[0]['media']['image'][0]['link'] !== '' ? content.content[0]['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                                                    width="100"
                                                                                                                    height="100"
                                                                                                                    fit='crop'
                                                                                                                    type='img'
                                                                                                                    //sClass="img-fluid" 
                                                                                                                    alt={content.content[0]['media']['image'][0]['name']}
                                                                                                                />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    :
                                                                                                        null
                                                                                            }
                                                                                            <div className='item-content'>
                                                                                                <div className='item-name-price'>
                                                                                                    <div className="item-name">
                                                                                                        {(lang === 'pt' || content.content[0].language === undefined ? content.content[0].title : (content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id].title === undefined || content.content[0].language[lang_id].title === '' ? content.content[0].title : content.content[0].language[lang_id].title))}
                                                                                                    </div>
                                                                                                    <div className='item-divider'></div>
                                                                                                    {
                                                                                                       content.content[0].price !== undefined && content.content[0].price !== '' ?
                                                                                                            <div className='item-price'>
                                                                                                                &euro;{content.content[0].price}
                                                                                                            </div>
                                                                                                        :
                                                                                                            null 
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    content.content[0].text !== undefined && content.content[0].text !== '' && content.content[0].text !== '<p></p>\n' ?

                                                                                                        <div className="item-text">
                                                                                                            {ReactHtmlParser(lang === 'pt' || content.content[0].language === undefined ? content.content[0].text : (content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id] === null || content.content[0].language[lang_id].text === undefined || content.content[0].language[lang_id].text === '' || content.content[0].language[lang_id].text === '<p></p>\n' ? content.content[0].text : content.content[0].language[lang_id].text))}
                                                                                                        </div>
                                                                                                    : null 
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            :
                                                                null
                                                            
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                     </div>
                                </div>
                            </div>
                        :
                            null
                    }
                                
                    {
                        list_content.length > 0 && 0 ?
                            <div className="row justify-content-center">
                                {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-6 col-md-4 col-lg-3 mb-4">
                                                        <a href={'./menu/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)} title={(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}>
                                                            <div className="card">
                                                                <div className="menu-image">
                                                                    {
                                                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                                            <AssecImage 
                                                                                linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                                                width="400"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                                            />
                                                                        :
                                                                            el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                <AssecImage 
                                                                                    linkImage={el['media']['image'][0]['link'] !== '' ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                    width="400"
                                                                                    height="300"
                                                                                    fit='crop'
                                                                                    type='img'
                                                                                    sClass="img-fluid" 
                                                                                    alt={el['media']['image'][0]['name']}
                                                                                />
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                                <div className="card-body text-center">
                                                                    <div className="menu-title">
                                                                        {(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
                                                                    </div>
                                                                    {/*<div className="service-text">
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                    </div>*/}                                                            
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                        :
                         null
                    } 
                </div>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}