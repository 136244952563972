import React, { useState, useEffect, useRef } from 'react';

import { reqGET, repo_img_link, content_language, translate } from '../../../../Utils';

import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

import parse from 'html-react-parser';
import AssecImage from "../../ASSEC_Image/Image"

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeProducts_03.css"

const HomeProducts = (props) => {

	const [, set_dados] = useState(null)
	const products_list = useRef([])
	const dados_config = useRef([])

    const [products_title, set_products_title] = useState('')
    const [products_subtitle, set_products_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'product' && el.info.active) {
                    set_products_title(translate(el.info.title, lang))
                    set_products_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        let getProducts = async () => {
            reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/product`)
                .then(res => {

                    set_dados(products_list.current = res.data)
                    setLoading(false)

                }).catch(erro => console.log('Erro1', erro))
        }
        getProducts()

    }, [props, lang])

    function get_image_content(el, type) {
  
        let result = ''
  
        if(parseInt(lang_id) !== 1 && el.content[0]['language'] !== undefined && el.content[0]['language'][lang_id] !== undefined && el.content[0]['language'][lang_id] !== null && el.content[0]['language'][lang_id]['media'] !== null && el.content[0]['language'][lang_id]['media']['image'] !== undefined && el.content[0].language[lang_id]['media']['image'].length > 0) { 
          if(type === 'name') {
            result = el.content[0].language[lang_id].media.image[0].name
          }
          else if(type === 'url') {
            result = el.content[0].language[lang_id].media.image[0].url
          }
          else if(type === 'link') {
            result = el.content[0].language[lang_id].media.image[0].link
          }
        } else {
          if(el.content[0].media.image !== undefined && el.content[0].media.image !== null && el.content[0].media.image.length > 0) {
  
            if(type === 'name') {
              result = el.content[0].media.image[0].name
            }
            else if(type === 'url') {
              result = el.content[0].media.image[0].url
            }
            else if(type === 'link') {
              result = el.content[0].media.image[0].link
            }
    
          }
  
        }
  
      return result
    }

    return (
        isloading ?
            <Loader />
		:   
            <section id="homeproducts_03" className='div-component'>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 title-properties">
                    <h3 className="titulo-pp">
                      {products_title}
                    </h3>
                    {
                      products_subtitle !== '' ?
                        <h4 className={"subtitulo-pp"}>
                          {products_subtitle}
                        </h4>
                      :
                       null
                    }
                    <hr></hr>
                  </div>
                  <div className="col-12 text-end mb-3">
                      <a className="btn btn-expand" href={'./product/all'} >
                          <span>{language[lang].see_all}</span>
                          <i className="bi bi-arrow-right"></i>
                      </a>
                  </div>
                  {
                    products_list.current !== undefined && products_list.current !== null && products_list.current.length > 0 ?
                      <>
                        <div className="col-12">
                          <div className="row g-3 justify-content-center">
                          {
                            products_list.current.map((v, k) => {
                              if(k < 6) {
                                return (
                                  <div className="col-6 col-md-4 col-lg-2 mb-4" key={k}>
                                    <a href={"product/" + v.id} title={language[lang].learn_more + ' - ' + parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}>
                                      <div className="card hover-product border-0">
                                        <div className="card-img-top product-image">
                                          <AssecImage
                                            linkImage={get_image_content(v, 'link') !== '' ? get_image_content(v, 'link') : repo_img_link('nophoto.webp')}
                                            width="450"
                                            height="350"
                                            fit='crop'
                                            type='img'
                                            sClass='img-fluid'
                                            alt={get_image_content(v, 'name')}
                                            title={get_image_content(v, 'name')}
                                          />
                                          <div className='hover-product-overlay'></div>
                                          <div className="hover-product-content">
                                            <div className="product-title">
                                              {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                )
                              } else {
                                return null
                              }
                            })
                            }
                          </div>
                        </div>
                      </>
                    :
                      null
                  }
                  <div className="col-12 mt-5 text-center d-none">
                    <a className="btn btn-primary" href={'./product/all'} >{language[lang].see_it_all}</a>
                  </div>
                </div>
              </div>
            </section>
    )
};
export default HomeProducts;