import React, { useState, useEffect, useRef } from 'react'
//import { useParams } from "react-router-dom";

import * as Hosts from '../../../Hosts';
import { /*reqGET,*/ translate, content_language } from '../../../Utils';
import { language } from '../WEBContent/Language';
//import { getTokenInfo } from '../../../UseToken';

import './Breadcrumb.css';

export default function Breadcrumb(props) {

    //const { pag_id, slug, article_id, gallery_id, preview_id, routes_id, interest_id, insight_id, partner_id, accommodation_id, restaurant_id, service_id, brand_id, product_id, form_slug } = useParams();
    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    //const [content, set_content] = useState(data_interface);
    const [menu, set_menu] = useState([]);

    const [, set_dados] = useState(null);
	const content = useRef(null);

    const [news_title, set_news_title] = useState(language[lang].news)
    const [partner_title, set_partner_title] = useState(language[lang].partners)
    const [gallery_title, set_gallery_title] = useState(language[lang].galleries)
    const [service_title, set_service_title] = useState(language[lang].services)
    const [product_title, set_product_title] = useState(language[lang].products)
    const [brand_title, set_brand_title] = useState(language[lang].brands)
    const [menu_title, set_menu_title] = useState(language[lang].menu)

    useEffect(() => {

        //console.log(props);

        if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
            set_menu(props.dados_navbar)
        }
        if(props.dados_content !== undefined && props.dados_content !== null && props.dados_content !== '') {
            set_dados(content.current = props.dados_content)
        }
        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            for (let el of props.dados_config.content[0].components) {

                if(el.info.tag === 'news' && el.info.active) {
                    set_news_title(translate(el.info.title, lang))
                } else if(el.info.tag === 'partner' && el.info.active) {
                    set_partner_title(translate(el.info.title, lang));
                } else if(el.info.tag === 'gallery' && el.info.active) {
                    set_gallery_title(translate(el.info.title, lang));
                } else if(el.info.tag === 'service' && el.info.active) {
                    set_service_title(translate(el.info.title, lang));
                } else if(el.info.tag === 'product' && el.info.active) {
                    set_product_title(translate(el.info.title, lang));
                } else if(el.info.tag === 'brand' && el.info.active) {
                    set_brand_title(translate(el.info.title, lang));
                } else if(el.info.tag === 'tag_menu' && el.info.active) {
                    set_menu_title(translate(el.info.title, lang));
                } else {}
            }
        }

        /*const data_interface = {
            id: '',
            info: {},
            content: {}
        }*/      

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        setLoading(false)

    }, [props, lang]);

    return (isloading ?
		null
	:
        <>
            <nav className="breadcrumb-container" aria-label="breadcrumb">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={Hosts.WEB_SITE_URI}>Home</a></li>
                        {
                        content.current.info.type === 'news' || content.current.info.type === 'insight' || content.current.info.type === 'routes' || content.current.info.type === 'partner' || content.current.info.type === 'accommodation' || content.current.info.type === 'restaurant'|| content.current.info.type === 'service' || content.current.info.type === 'product' || content.current.info.type === 'brand' || content.current.info.type === 'menu' || content.current.info.type === 'career' || content.current.info.type === 'gallery' || content.current.info.type === 'contact' ? 
                            content.current.id !== '' ?
                                (() => {
                                    switch (content.current.info.type) {
                                        case 'news':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'article/all'}>{news_title}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'insight':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'insight/all'}>{language[lang].insights}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'routes':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'routes/all'}>{language[lang].routes}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'partner':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'partner/all'}>{partner_title}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'gallery':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'gallery/all'}>{gallery_title}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'accommodation':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'accommodation/all'}>{language[lang].alojamento}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'restaurant':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                    <li className="breadcrumb-item text-truncate 00 "><a href={Hosts.WEB_SITE_URI + 'restaurant/all'}>{language[lang].restauracao}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'service':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'service/all'}>{service_title}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'product':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'product/all'}>{product_title}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        case 'brand':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'brand/all'}>{brand_title}</a></li>
                                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                    </li>
                                                </>
                                            )
                                        default:
                                            return null
                                    }
                                })()
                            : 
                                (() => {
                                    switch (content.current.info.type) {
                                        case 'news':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'article/all'}>{news_title}</a></li>
                                                </>
                                            )
                                        case 'insight':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'insight/all'}>{language[lang].insights}</a></li>
                                                </>
                                            )
                                        case 'routes':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'routes/all'}>{language[lang].routes}</a></li>
                                                </>
                                            )
                                        case 'partner':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'partner/all'}>{partner_title}</a></li>
                                                </>
                                            )
                                        case 'gallery':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'gallery/all'}>{gallery_title}</a></li>
                                                </>
                                            )
                                        case 'career':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'form/carreiras'}>{language[lang].careers}</a></li>
                                                </>
                                            )
                                        case 'accommodation':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'accommodation/all'}>{language[lang].alojamento}</a></li>
                                                </>
                                            )
                                        case 'restaurant':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'restaurant/all'}>{language[lang].restauracao}</a></li>
                                                </>
                                            )
                                        case 'service':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'service/all'}>{service_title}</a></li>
                                                </>
                                            )
                                        case 'product':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'product/all'}>{product_title}</a></li>
                                                </>
                                            )
                                        case 'brand':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate active">
                                                        {/*<a href={Hosts.WEB_SITE_URI + 'brand/all'}>
                                                            {brand_title}
                                                        </a>*/}
                                                        {brand_title}
                                                    </li>
                                                </>
                                            )
                                        case 'menu':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate active">
                                                        {menu_title}
                                                    </li>
                                                </>
                                            )
                                        case 'contact':
                                            return (
                                                <>
                                                    <li className="breadcrumb-item text-truncate">{language[lang].contacts}</li>
                                                </>
                                            )
                                        default:
                                            return null
                                    }
                                })()
                        :
                            <>
                                {
                                menu.content !== undefined ?
                                    menu.content.childs.map((v, key) => {
                                        return (
                                            content.current.info['parent'] !== undefined && v.id_content === content.current.info.parent ?
                                                <li key={key} className="breadcrumb-item active text-truncate" aria-current="page">
                                                    {lang === 'pt' || v.language === undefined ? v.title : (v.language[lang_id] === undefined || v.language[lang_id].title === undefined || v.language[lang_id].title === '' ? v.title : v.language[lang_id].title)}
                                                </li>
                                                
                                            :
                                                v.link === content.current.info.type ?
                                                    <li key={key} className="breadcrumb-item active text-truncate" aria-current="page">
                                                        {lang === 'pt' || v.language === undefined ? v.title : (v.language[lang_id] === undefined || v.language[lang_id].title === undefined || v.language[lang_id].title === '' ? v.title : v.language[lang_id].title)}
                                                    </li>
                                                :
                                                    null
                                        )
                                    })
                                : 
                                    null
                                }
                                {
                                content.current.info.name !== undefined ?
                                    <li className="breadcrumb-item active text-truncate" aria-current="page">
                                        {/*content.current.info.name*/}
                                        {lang === 'pt' || content.current.info.language === undefined ? content.current.info.name : (content.current.info.language[lang_id] === undefined || content.current.info.language[lang_id].name === undefined || content.current.info.language[lang_id].name === '' ? content.current.info.name : content.current.info.language[lang_id].name)}
                                    </li> 
                                : 
                                    null
                                }
                                {/*
                                    //páginas manuais (caso seja necessário)
                                    content.info.type === 'accesses' || content.info.type === 'livecam' ? 

                                        <li className="breadcrumb-item active" aria-current="page">{language[lang][content.info.type]}</li>
                                        
                                    : 
                                        ''
                                */}  
                            </>
                        }
                    </ol>
                </div>
            </nav>
        </>

    )
}