import React, { useState, useEffect, useRef } from 'react';

import { reqGET, repo_img_link, content_language, translate } from '../../../../Utils';

import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

//import parse from 'html-react-parser';

import { Navigation, Pagination, Autoplay, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import "swiper/swiper-bundle.min.css";

import AssecImage from "../../ASSEC_Image/Image"

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomePartners_02.css"

const HomePartners = (props) => {

	const [, set_dados] = useState(null)
	const partners_list = useRef([])
	const dados_config = useRef([])

    const [partners_title, set_partners_title] = useState('')
    const [partners_subtitle, set_partners_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'partner' && el.info.active) {
                    set_partners_title(translate(el.info.title, lang))
                    set_partners_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        let getPartners = async () => {
            reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/partner`)
                .then(res => {

                    set_dados(partners_list.current = res.data)
                    setLoading(false)

                }).catch(erro => console.log('Erro1', erro))
        }
        getPartners()

    }, [props, lang])

    function get_image_content(el, type) {
  
        let result = ''
  
        if(parseInt(lang_id) !== 1 && el.content[0]['language'] !== undefined && el.content[0]['language'][lang_id] !== undefined && el.content[0]['language'][lang_id] !== null && el.content[0]['language'][lang_id]['media'] !== null && el.content[0]['language'][lang_id]['media']['image'] !== undefined && el.content[0].language[lang_id]['media']['image'].length > 0) { 
          if(type === 'name') {
            result = el.content[0].language[lang_id].media.image[0].name
          }
          else if(type === 'url') {
            result = el.content[0].language[lang_id].media.image[0].url
          }
          else if(type === 'link') {
            result = el.content[0].language[lang_id].media.image[0].link
          }
        } else {
          if(el.content[0].media.image !== undefined && el.content[0].media.image !== null && el.content[0].media.image.length > 0) {
  
            if(type === 'name') {
              result = el.content[0].media.image[0].name
            }
            else if(type === 'url') {
              result = el.content[0].media.image[0].url
            }
            else if(type === 'link') {
              result = el.content[0].media.image[0].link
            }
    
          }
  
        }
  
      return result
    }

    return (
        isloading ?
            <Loader />
		:
            <section id="homepartners_02" className='div-component'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {partners_title}
                            </h3>
                            {
                                partners_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                    {partners_subtitle}
                                    </h4>
                                :
                                null
                            }
                            <hr></hr>
                        </div>
                        <div className="col-12 text-end mb-3">
                            <a className="btn btn-expand" href={'./partner/all'} >
                                <span>{language[lang].see_all}</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                        
                        <div className="col-12">
                            {
                            partners_list.current !== undefined && partners_list.current !== null && partners_list.current.length > 0 ?
                                <div className="swiper-container swiper-partners">
                                    <Swiper className="p-1" modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                                        speed={500}
                                        loop={true}
                                        autoHeight={false}
                                        autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                        }}
                                        spaceBetween={15}
                                        slidesPerView={2}
                                    //navigation
                                        pagination={{
                                        el: '.swiper-pagination-partners',
                                        clickable: true
                                        }}
                                    //scrollbar={{ draggable: true }}
                                    breakpoints={{
                                        // when window width is >= 0px
                                        0: {
                                        slidesPerView: 2,
                                        //slidesPerGroup: 2
                                        },
                                        // when window width is >= 768px
                                        768: {
                                        slidesPerView: 3,
                                        //lidesPerGroup: 3
                                        },
                                        992: {
                                        slidesPerView: 3,
                                        //lidesPerGroup: 3
                                        },
                                        1200: {
                                        slidesPerView: 3,
                                        //slidesPerGroup: 3
                                        },
                                    }}
                                    >
                                        {
                                        partners_list.current.map((v, k) => {
                                            if(k < 9) {
                                                return (
                                                    <SwiperSlide key={k}>
                                                        <a href={'./partner/' + v.id} title={language[lang].learn_more + ' - ' + (lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}>
                                                            <div className="card">
                                                                <div className="card-img-top">
                                                                    <AssecImage
                                                                        linkImage={get_image_content(v, 'link') !== '' ? get_image_content(v, 'link') : repo_img_link('nophoto.webp')}
                                                                        width="500"
                                                                        height="400"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass='img-fluid'
                                                                        alt={get_image_content(v, 'name')}
                                                                        title={get_image_content(v, 'name')}
                                                                    />
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="partner-title">
                                                                        {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}  
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </SwiperSlide>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                        }
                                    </Swiper>
                                    <div className="swiper-pagination-partners mt-4"></div>
                                </div>
                            :
                                null
                            }
                        </div>
                    
                    </div>
                </div>
            </section>
    )
};
export default HomePartners;